import Axios from "axios";
import { Message } from "element-ui";
import utils from "@/common/utils";
import { sendErrorLog } from '@/services/common';

const sendError = (response) => {
  if (process.env.NODE_ENV !== "production") return;
  const {
    config: { method, url, data, params },
    data: responseData,
    status,
  } = response;
  if (url === "https://intl.iqiyi.com/operation-external/front-log") return;
  sendErrorLog({
    url,
    httpMethod: method,
    responseCode: status,
    responseData: JSON.stringify(responseData),
    requestData: JSON.stringify(
      ["get", "delete"].includes(method) ? params : data
    ),
  });
};

let axios = Axios.create();
//http request 拦截器 为全局添加参数
axios.interceptors.request.use((config) => {
  // 检查请求地址是否包含 "/api-intl-translate/login"
  if (!config.url.includes('/api-intl-translate/login')) {
    // 如果不包含，则添加 Opsession 头部
    config.headers['Opsession'] = utils.getCookie('OPSESSION');
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    var data = response.data;
    if (data.code === 0) {
      if (response.headers.opsession) {
        return response;
      } else {
        return data.data;
      }
    } else {
      sendError(response);
      return Promise.reject(data);
    }
  },
  //处理其他状态码
  (error) => {
    let status = error.response.status;
    if(status != 401 && status != 403) {
      sendError(error.response);
    }
    if (error?.response?.data?.message) {
      Message.error(error.response.data.message);
    } else if (status >= 500) {
      Message.error("服务器异常");
    } else if (status >= 400) {
      Message.error("客户端异常");
    }
    return Promise.reject(error);
  }
);

export default axios;
