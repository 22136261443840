export default [
	{
		name: 'vc_admin_project',
		router: '/vc/admin-project'
	},
	{
		name: 'vc_admin_project_create',
		router: '/vc/admin-project-create'
	},
	{
		name: 'vc_admin_project_edit',
		router: '/vc/admin-project-edit'
	},
	{
		name: 'vc_admin_project_track_create',
		router: '/vc/admin-project-audio-track-create'
	},
	{
		name: 'vc_admin_project_track_detail',
		router: '/vc/admin-project-audio-track-detail'
	},
	{
		name: 'vc_admin_project_file',
		router: '/vc/admin-project-file'
	},
	{
		name: 'vc_admin_project_file_dubbing_material',
		router: '/vc/admin-project-file-dubbing-material'
	},
	{
		name: 'vc_admin_project_file_intl_voice',
		router: '/vc/admin-project-file-intl-voice'
	},
	{
		name: 'vc_admin_project_file_manual_dubbing_material',
		router: '/vc/admin-project-file-manual-dubbing-material'
	},
	{
		name: 'vc_admin_project_file_synthetic_audio',
		router: '/vc/admin-project-file-synthetic-audio'
	},
	{
		name: 'vc_admin_project_file_mix_audio',
		router: '/vc/admin-project-file-mix-audio'
	},
	{
		name: 'vc_admin_project_file_others',
		router: '/vc/admin-project-file-others'
	},
	{
		name: 'vc_admin_project_production',
		router: '/vc/admin-project-production'
	},
	{
		name: 'vc_admin_project_log',
		router: '/vc/admin-project-log'
	},
	{
		name: 'vc_admin_audit_list',
		router: '/vc/admin-audit-list'
	},
	{
		name: 'vc_admin_audit_detail',
		router: '/vc/admin-audit-detail'
	},
	{
		name: 'vc_cooperation_manage',
		router: '/vc/cooperation-manage'
	},

	// 合作方
  {
    name: 'vc_cooperation',
    router: '/vc/coo-project',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1628733627559_939'
  },
  {
    name: 'vc_coo_project',
    router: '/vc/coo-project',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1629443395102_374'
  },
  {
    name: 'vc_coo_track_create',
    router: '/vc/coo-project-audio-track-create',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1628733645235_44'
  },
  {
    name: 'vc_coo_project_material_list',
    router: '/vc/coo-project-material-list',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1629443616656_791'
  },
  {
    name: 'vc_coo_task',
    router: '/vc/coo-task',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1628733629216_37'
  },
];
